import React, { useRef, useEffect } from 'react';
import {IDocumentUpload} from './documentUpload.types';
import {
  StyledDocumentUpload,
  StyledDocumetDetails,
  StyledTitle,
  StyledCopy,
  StyledDocumentContainer,
  StyledFilename,
  StyledOptions,
  StyledButtonInput,
  StyledError,
} from './documentUpload.styles';
import {Card} from '../card';
import {transformMarkdown} from '../../../utils/markdownHelper'
import { Button } from 'hult-toolkit';

export const DocumentUpload: React.FC<IDocumentUpload> = ({
  state,
  title,
  copy,
  filename,
  accept,
  onChange,
  viewHandler,
  deleteHandler,
}) => {
  const copyRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (copyRef.current && copy) {
      copyRef.current.innerHTML = transformMarkdown(copy).replace(/<\/?span[^>]*>/g, "");
    }
  }, [copy]);

  const containsSpecialCharacters = (filename: string): boolean =>
    /[&"'?<>#{}%~/\\]/.test(filename);

  return (
    <Card loading={state === 'loading'}>
      <StyledDocumentUpload>
        <StyledDocumetDetails>
          {title && <StyledTitle state={state}>{title}</StyledTitle>}
          {copy && <StyledCopy state={state} ref={copyRef}></StyledCopy>}
        </StyledDocumetDetails>
        {state === 'uploaded' ? (
          <StyledDocumentContainer state={state}>
            <StyledFilename>
              {filename && <p>{filename}</p>}
            </StyledFilename>
            <StyledOptions>
              <Button
                label={'View'}
                variant={'ghost'}
                icon={{
                  animate: '',
                  library: 'solid',
                  name: 'eye',
                }}
                icon_reverse={'true'}
                size={'small'}
                Wrapper="button"
                WrapperProps={{onClick: (e: any) => viewHandler && viewHandler(e)}}
              />
              <Button
                label={'Delete'}
                variant={'ghost'}
                icon={{
                  animate: '',
                  library: 'solid',
                  name: 'times',
                }}
                icon_reverse={'true'}
                size={'small'}
                Wrapper="button"
                WrapperProps={{onClick: (e: any) => deleteHandler && deleteHandler(e)}}
              />
            </StyledOptions>
          </StyledDocumentContainer>
        ) : (
          <StyledDocumentContainer state={state}>
            <Button
              label={'Add document'}
              variant={state === 'loading' ? 'disabled' : 'tertiary'}
              icon={{
                animate: '',
                library: 'solid',
                name: 'plus',
              }}
            />
            <StyledButtonInput
              type="file"
              accept={accept}
              onChange={onChange}
            />
          </StyledDocumentContainer>
        )}
      </StyledDocumentUpload>
    </Card>
  );
};
